 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item class="el_form_item" label="用户姓名">
				<el-input class="el_input" v-model="form.name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="用户手机">
				<el-input class="el_input" v-model="form.tel" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="用户状态">
				<el-select class="el_input" v-model="form.is_use" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="正常" value="1"></el-option>
					<el-option label="停用" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="用户组">
				<el-select class="el_input" v-model="form.ug_id" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option v-for="(item,index) in ug_list" :key="index" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<el-button type="success" @click="open_add_view">添加</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column width="100" label="ID" prop="id"></el-table-column>
				<el-table-column width="220" label="添加时间" prop="creat_time_text"></el-table-column>
				<el-table-column width="220" label="电话号码" prop="tel"></el-table-column>
				<el-table-column label="员工姓名" prop="name"></el-table-column>
				<el-table-column label="用户组">
					<template slot-scope="scope">
						<div>{{scope.row.ug_info.name}}</div>
					</template>
				</el-table-column>
				
				<!-- 操作行 -->
				<el-table-column label="操作" width="200">
					<template slot-scope="scope">
						<el-button v-if="scope.row.is_use==1" @click="edit_sub({id:scope.row.id,is_use:2})" size="mini" type="text" icon="el-icon-circle-close">停用</el-button>
						<el-button v-if="scope.row.is_use==2" @click="edit_sub({id:scope.row.id,is_use:1})" size="mini" type="text" icon="el-icon-circle-check">启用</el-button>
						<el-button @click="open_edit_view(scope.row)" size="mini" type="text" icon="el-icon-edit">修改</el-button>
						<el-button @click="del(scope.row.id)" size="mini" type="text" icon="el-icon-circle-close">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>

		<!-- 用户数据修改弹窗 -->
		<el-dialog top="3vh" title="修改用户" width="60%" :visible.sync="edit_para.is_show">
			<el-form status-icon label-position="left" label-width="70px">
				<div style="display:flex">
					<el-form-item label="用户姓名" >
						<el-input class="el_input" v-model="edit_para.user_info.name" ></el-input>
					</el-form-item>
					<el-form-item label="用户手机" >
						<el-input class="el_input" v-model="edit_para.user_info.tel" ></el-input>
					</el-form-item>
					<el-form-item label="是否启用" >
						<el-select class="el_input" v-model="edit_para.user_info.is_use" clearable>
							<el-option label="启用" value="1"></el-option>
							<el-option label="停用" value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="用户组" >
						<el-select class="el_input" v-model="edit_para.user_info.ug_id" clearable>
							<el-option v-for="(item,index) in ug_list" :key="index" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
				</div>
				<el-form-item label="额外权限" >
					<div class="menu_list">
						<div v-for="(main_item,index) in menu_list" :key="index">
							<div>{{main_item.name}}</div>
							<el-checkbox-group class="children_list" v-model="edit_para.user_info.menu">
								<el-checkbox v-for="(children_item,index) in main_item.children" :key="index" :label="children_item.id">{{children_item.name}}</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
				</el-form-item>
			</el-form>
			<div style="text-align:center">
				<el-button type="primary" @click="edit_view_sub">提交</el-button>
			</div>
		</el-dialog>

		<!-- 用户数据添加弹窗 -->
		<el-dialog top="3vh" title="创建用户" width="60%" :visible.sync="add_para.is_show">
			<el-form status-icon label-position="left" label-width="70px">
				<div style="display:flex">
					<el-form-item label="用户姓名" >
						<el-input class="el_input" v-model="add_para.user_info.name" ></el-input>
					</el-form-item>
					<el-form-item label="用户手机" >
						<el-input class="el_input" v-model="add_para.user_info.tel" ></el-input>
					</el-form-item>
					<el-form-item label="用户组" >
						<el-select class="el_input" v-model="add_para.user_info.ug_id" clearable>
							<el-option v-for="(item,index) in ug_list" :key="index" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
				</div>
				<el-form-item label="额外权限" >
					<div class="menu_list">
						<div v-for="(main_item,index) in menu_list" :key="index">
							<div>{{main_item.name}}</div>
							<el-checkbox-group class="children_list" v-model="add_para.user_info.menu">
								<el-checkbox v-for="(children_item,index) in main_item.children" :key="index" :label="children_item.id">{{children_item.name}}</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
				</el-form-item>
			</el-form>
			<div style="text-align:center">
				<el-button type="primary" @click="add_view_sub">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {

				//货物类型
				ug_list:[],

				//菜单列表
				menu_list:{},
				menu_list_loaded:false,//是否加载过

				//搜索条件
				form: {				
					name:'',//姓名
					tel:'',//电话
					is_use:'',//是否可用
					ug_id:'',//用户组
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//用户修改弹窗
				edit_para:{
					user_info:{},
					is_show:false,
				},

				//用户添加弹窗
				add_para:{
					user_info:{
						name:'',
						tel:'',
						ug_id:'',
						menu:[],
					},
					is_show:false,
				}
			}
		},
		created() {
			
			//获取可用搜索参数
			this.get_ug_list()

			//读取后台用户
			this.get_page_data()
		},
		methods: {

			//删除用户
			del(uid){

				//询问
				this.$my.other.confirm({
					content:"是否删除此用户",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'admin',
								ctr:'user_del_by_admin',
								id:uid
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.get_page_data()
							},
						});
					}
				});
			},

			//创建用户
			add_view_sub(){
				
				this.$my.other.confirm({
					content:"是否创建此用户",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'admin',
								ctr:'user_add_by_admin',
								...this.add_para.user_info
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.add_para.is_show=false;
								this.get_page_data()
							},
						});
					}
				});
			},

			//打开用户添加页面
			open_add_view(){
				this.get_menu_list()
				this.add_para.is_show=true
			},

			//提交修改
			edit_view_sub(){

				this.edit_sub({
					id:this.edit_para.user_info.id,
					name:this.edit_para.user_info.name,
					tel:this.edit_para.user_info.tel,
					is_use:this.edit_para.user_info.is_use,
					menu:this.edit_para.user_info.menu,
					ug_id:this.edit_para.user_info.ug_id,
				});
			},

			//打开用户数据修改页面
			open_edit_view(item){
				this.get_menu_list()
				this.edit_para.user_info=item
				this.edit_para.is_show=true
			},

			//提交修改
			edit_sub(obj){

				//提交
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'user_edit_by_admin',
						...obj
					},
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});
						this.edit_para.is_show=false;
						this.get_page_data()
					},
				});
			},

			//清空查询条件
			ser_para_clear(){
				this.form={
					name:'',//姓名
					tel:'',//电话
					is_use:'',//是否可用
					ug_id:'',//用户组
				}
				this.get_page_data()
			},
			
			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取后台用户
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'user_list_by_admin',
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//下单时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//额外权限
							item.menu=item.menu.split(',')
							for(var i in item.menu){
								if(!item.menu[i].trim())item.menu.splice(i,1)
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},

			//读取菜单列表
			get_menu_list(){
				console.log(111)
				//读取过
				if(this.menu_list_loaded){
					return
				}

				//去服务器读取
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'menu_list_by_admin',
						p:1,
						num:1000
					},
					callback:(data)=>{

						//格式化主菜单
						let menu_buf={}
						let menu_main=[];
						let menu_children={};
						for(var item of data.list){

							menu_buf[item.id]=item;

							if(item.fid==0){
								menu_main.push(item)
							}else{
								if(!menu_children[item.fid])menu_children[item.fid]=[];
								menu_children[item.fid].push(item)
							}
						}
						
						//格式化子菜单
						for(var i in menu_main){
							menu_main[i].children=menu_children[menu_main[i].id]
						}

						//置入
						this.menu_list=menu_main
						this.menu_list_loaded=true
					}
				})
			},

			//获取用户组
			get_ug_list(){

				//尝试从缓存中取出
				let ug_list=this.$my.cache.get('__admin_user_ug_list');

				//如果取出成功
				if(ug_list){
					this.ug_list=ug_list
					return
				}

				//如果取出失败,去服务器读取
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'ug_list_by_admin',
						p:1,
						num:1000
					},
					callback:(data)=>{

						//缓存之
						this.$my.cache.set({
							__admin_user_ug_list:[...data.list]
						});

						//置入
						this.ug_list=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//用户修改/添加表单的额外权限菜单部分
	//  
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 90px);
	}
	.el_form_item{
		width: 20%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>